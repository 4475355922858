import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import configData from "../config/config.json";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Card from "react-bootstrap/Card";

export default function ChangePassword() {
  const navigate = useNavigate();
  
  // VARIABLES
  
  const baseURL = configData.SERVER_URL;
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [email, setEmail] = useState();

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("light");

  // AXIOS

  async function postWorkingPeriod() {
    let data = {email: email};

    const config = {
      method: "post",
      url: baseURL + "/api/v1/user/forgot_password",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Bearer " + token,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setToastColor("primary");
          setToastMessage(
            "E-Mail erfolgreich versendet"
          );
          setShowToast(true);
      })
      .catch(function (error) {
        setToastColor("warning");
          setToastMessage(
            "Die E-Mail konnte nicht versendet werden. Bitte noch einmal probieren."
          );
          setShowToast(true);
      });
  }

  // HANDLERS

  function emailHandler(email) {
    setEmail(email);
  }

  function sendChangePasswordEmailButtonHandler() {
    
    if (email !== null) {
      postWorkingPeriod();
    }

  }

  function backToLoginButtonHandler() {
    navigate("/login");
  }

  return (
    <Container>
      <Row className="justify-content-center" style={{ padding: "1em" }}>
        <Col xs={12} md={2} lg={3}></Col>
        <Col xs={12} md={8} lg={6}>
          <Card style={{ padding: "1em", margin: "1em" }}>
            <Card.Body>
              <Row className="mb-3" style={{ padding: "1em" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="147.4"
                  height="49.133"
                  version="1.1"
                  viewBox="0 0 147.4 49.133"
                  xmlSpace="preserve"
                >
                  <g transform="matrix(1.33333 0 0 -1.33333 0 49.133)">
                    <g
                      fill="#231f20"
                      fillOpacity="1"
                      fillRule="nonzero"
                      stroke="none"
                      transform="scale(.1)"
                    >
                      <path d="M388.227 4.098c-8.969 0-17.301 1.636-24.762 4.859-7.516 3.238-13.867 7.711-18.879 13.273a5.79 5.79 0 00-1.484 4.235c.089 1.543.789 3 1.953 4.031l3.172 2.79c2.363 2.108 5.972 1.925 8.125-.403 4.109-4.442 8.961-7.817 14.406-10.028 9.469-3.836 20.195-4.503 29.258-1.535 3.66 1.207 6.937 2.946 9.742 5.172 2.683 2.14 4.754 4.723 6.316 7.895 1.524 3.086 2.289 6.77 2.289 10.93 0 3.996-.781 7.3-2.328 9.835-1.73 2.856-3.914 5.246-6.687 7.293-2.926 2.168-6.336 4.02-10.114 5.496a189.603 189.603 0 01-12.57 4.426c-5.687 1.813-10.902 3.723-15.527 5.695-5.067 2.153-9.516 4.844-13.239 8.008a34.938 34.938 0 00-9.156 12.063c-2.14 4.66-3.23 10.269-3.23 16.683 0 6.372 1.304 12.176 3.894 17.266 2.543 5.027 6.051 9.316 10.449 12.758 4.262 3.336 9.235 5.914 14.786 7.672 12.257 3.851 26.543 3.453 38.679-1.684 6.883-2.91 12.625-7.14 17.059-12.582a5.822 5.822 0 00-.668-8.047l-3.168-2.789c-1.199-1.055-2.746-1.539-4.352-1.433a5.814 5.814 0 00-4.043 2.164c-2.925 3.652-6.57 6.418-11.152 8.472-7.898 3.543-17.711 4.098-26.762 1.496-3.593-1.046-6.898-2.656-9.808-4.793-2.809-2.054-5.004-4.57-6.719-7.695-1.629-2.965-2.457-6.601-2.457-10.805 0-4.105.723-7.558 2.133-10.234a22.304 22.304 0 015.933-7.203c2.668-2.137 5.739-3.926 9.114-5.313a171.31 171.31 0 0111.644-4.328 313.264 313.264 0 0015.953-5.793c5.309-2.11 10.051-4.715 14.102-7.742 4.351-3.238 7.793-7.254 10.242-11.941 2.481-4.723 3.738-10.434 3.738-16.946 0-6.464-1.304-12.379-3.867-17.566-2.508-5.11-5.914-9.496-10.121-13.055-4.183-3.53-9.152-6.215-14.762-7.996-5.449-1.722-11.218-2.601-17.132-2.601"></path>
                      <path d="M531.699 4.098c-10.691 0-20.605 1.91-29.484 5.68-8.879 3.765-16.672 9.066-23.149 15.745-6.472 6.688-11.57 14.766-15.132 24.047-3.532 9.192-5.321 19.305-5.321 30.032 0 10.726 1.789 20.839 5.321 30.039 3.562 9.269 8.66 17.367 15.132 24.039 6.493 6.687 14.309 11.996 23.262 15.761 16.469 6.922 36.977 7.727 55.285 1.129 8.582-3.09 16.012-7.867 22.078-14.215a5.829 5.829 0 00.164-7.859l-2.609-2.98a5.832 5.832 0 00-4.258-1.989c-1.394-.035-3.199.625-4.336 1.809a38.802 38.802 0 01-7.703 6.238 51.998 51.998 0 01-9.394 4.625c-3.336 1.235-6.696 2.137-10 2.684-12.739 2.129-23.789.422-33.321-3.778-6.992-3.07-13.046-7.339-18.015-12.691-4.989-5.383-8.922-11.797-11.684-19.074-2.773-7.309-4.183-15.3-4.183-23.738 0-8.454 1.378-16.461 4.109-23.77 2.699-7.262 6.598-13.668 11.57-19.027 4.957-5.356 11.016-9.63 18.012-12.707 12.957-5.696 29.984-6.536 45.824-.29 7.043 2.79 12.934 7.004 17.496 12.54a5.775 5.775 0 004.114 2.109 5.821 5.821 0 004.359-1.563l2.977-2.789a5.815 5.815 0 00.492-7.976c-6.207-7.445-14.121-13.07-23.524-16.707-9.136-3.535-18.59-5.324-28.082-5.324"></path>
                      <path d="M674.23 4.098c-10.421 0-20.273 1.91-29.261 5.672-9.008 3.757-16.895 9.058-23.43 15.738-6.535 6.672-11.719 14.707-15.406 23.89-3.684 9.149-5.547 19.317-5.547 30.204 0 10.894 1.863 21.054 5.547 30.211 3.687 9.191 8.871 17.226 15.406 23.898 6.535 6.672 14.383 11.965 23.32 15.73 17.922 7.532 40.821 7.532 58.743 0 8.949-3.765 16.793-9.058 23.328-15.73 6.543-6.688 11.73-14.731 15.406-23.898 3.676-9.172 5.543-19.333 5.543-30.211 0-10.872-1.867-21.032-5.543-30.204-3.668-9.168-8.856-17.203-15.406-23.89-6.543-6.688-14.426-11.98-23.434-15.738-8.992-3.762-18.84-5.672-29.266-5.672zm0 135.64c-8.476 0-16.343-1.562-23.378-4.633-7.067-3.085-13.231-7.367-18.329-12.738-5.101-5.383-9.113-11.797-11.921-19.058-2.836-7.309-4.27-15.286-4.27-23.707 0-8.446 1.402-16.43 4.184-23.73 2.761-7.27 6.711-13.669 11.754-19.052 5.015-5.355 11.144-9.629 18.21-12.715 14.114-6.156 33.387-6.156 47.504 0 7.075 3.086 13.2 7.36 18.204 12.715 5.05 5.383 9.007 11.797 11.757 19.043 2.778 7.317 4.188 15.301 4.188 23.739 0 8.421-1.434 16.398-4.27 23.707-2.812 7.269-6.832 13.683-11.925 19.058-5.102 5.371-11.266 9.66-18.317 12.731-7.043 3.078-14.914 4.64-23.391 4.64"></path>
                      <path d="M788.637 7.82h-4.102a5.822 5.822 0 00-5.82 5.82v131.923a5.82 5.82 0 005.82 5.82h35.778c5.14 0 10.378-.582 15.585-1.719 5.5-1.199 10.547-3.246 15.012-6.082 4.68-2.973 8.547-7.019 11.496-12.023 3.008-5.11 4.532-11.434 4.532-18.789 0-6.426-1.227-12.258-3.645-17.325-2.402-5.03-5.727-9.308-9.855-12.699-4.028-3.32-8.782-5.867-14.133-7.562-5.188-1.668-10.766-2.52-16.575-2.52h-28.269V13.641c0-3.211-2.609-5.82-5.824-5.82zm5.824 80.032h28.461c3.555 0 7.18.492 10.773 1.468 3.344.918 6.368 2.407 8.961 4.407 2.563 1.957 4.586 4.449 6.188 7.613 1.558 3.07 2.347 6.922 2.347 11.43 0 4.55-.808 8.281-2.41 11.101-1.676 2.957-3.761 5.231-6.359 6.973-2.762 1.844-5.922 3.203-9.395 4.027-3.691.879-7.406 1.328-11.047 1.328h-27.519V87.852"></path>
                      <path d="M980.359 7.82h-79.933a5.823 5.823 0 00-5.821 5.82v131.923a5.82 5.82 0 005.821 5.82h76.953a5.823 5.823 0 005.824-5.82v-3.723a5.826 5.826 0 00-5.824-5.824h-67.027V89.715h62.742a5.823 5.823 0 005.824-5.82v-3.73a5.826 5.826 0 00-5.824-5.825h-62.742V23.195h70.007a5.824 5.824 0 005.821-5.824v-3.73c0-3.211-2.606-5.82-5.821-5.82"></path>
                      <path d="M7.156 233.898c0 3.043 2.016 4.067 4.473 2.293L217.316 87.109c2.45-1.773 4.473-5.715 4.473-8.761V11.395c0-3.032-2.246-4.465-4.996-3.184L12.082 104.05c-2.75 1.289-4.996 4.82-4.996 7.859l.07 121.988"></path>
                      <path d="M18.48 243.984c-2.453 1.774-2.296 4.442.36 5.93l198.133 110.887c2.656 1.48 4.816.203 4.816-2.828v-67.028c0-3.039-1.828-7.207-4.066-9.261L110.992 183.73c-2.238-2.054-6.097-2.285-8.547-.503l-83.964 60.757"></path>
                      <path d="M402.613 218.363c-8 0-15.543 1.305-22.414 3.875-6.929 2.61-13.023 6.356-18.148 11.145-5.172 4.832-9.242 10.765-12.117 17.652-2.852 6.84-4.305 14.578-4.305 23.012v83.476a3.882 3.882 0 003.879 3.883h23.48a3.877 3.877 0 003.883-3.883V274.98c0-3.503.465-7.023 1.359-10.472.864-3.266 2.29-6.219 4.262-8.774 1.926-2.484 4.531-4.562 7.77-6.156 3.238-1.59 7.39-2.394 12.351-2.394 4.973 0 9.129.804 12.36 2.394 3.238 1.594 5.851 3.672 7.761 6.156 1.973 2.555 3.414 5.508 4.262 8.774a41.054 41.054 0 011.367 10.472v82.543a3.877 3.877 0 003.879 3.883h23.477a3.875 3.875 0 003.883-3.883v-83.476c0-8.445-1.481-16.203-4.407-23.043-2.933-6.859-7-12.781-12.078-17.606-5.066-4.796-11.144-8.55-18.074-11.16-6.887-2.57-14.422-3.875-22.43-3.875"></path>
                      <path d="M596.59 221.715h-28.324a3.89 3.89 0 00-3.407 2.023l-29.453 53.879h-10.957v-52.019a3.88 3.88 0 00-3.883-3.883h-23.48a3.888 3.888 0 00-3.883 3.883v131.925a3.885 3.885 0 003.883 3.883h45.844c6.465 0 12.824-.687 18.922-2.054 6.277-1.395 11.949-3.747 16.847-6.973 5.028-3.309 9.114-7.703 12.145-13.074 3.051-5.414 4.594-12.008 4.594-19.606 0-10.504-3.055-19.246-9.09-25.976-4.891-5.446-11.266-9.469-19.008-11.996l32.574-54.129a3.879 3.879 0 00-3.324-5.883zm-72.141 82.863h15.309c3.183 0 6.379.238 9.492.699 2.926.434 5.559 1.243 7.832 2.411 2.113 1.085 3.805 2.585 5.18 4.609 1.289 1.883 1.937 4.43 1.937 7.59 0 2.769-.574 4.996-1.711 6.621a14.093 14.093 0 01-4.847 4.32c-2.071 1.137-4.496 1.942-7.215 2.367-2.965.461-5.86.696-8.621.696h-17.356v-29.313"></path>
                      <path d="M678.676 221.715h-47.524a3.885 3.885 0 00-3.882 3.883v131.925a3.882 3.882 0 003.882 3.883h51.438c5.238 0 10.5-.629 15.633-1.871 5.304-1.305 10.105-3.441 14.269-6.383 4.238-2.98 7.735-6.886 10.379-11.617 2.668-4.793 4.027-10.57 4.027-17.176 0-8.382-2.449-15.449-7.289-20.992-2.425-2.789-5.254-5.172-8.453-7.156 4.617-1.875 8.699-4.566 12.184-8.055 6.074-6.066 9.152-14.191 9.152-24.172 0-7.879-1.621-14.648-4.82-20.121-3.141-5.406-7.356-9.828-12.52-13.148-5.027-3.215-10.843-5.551-17.308-6.945a90.47 90.47 0 00-19.168-2.055zm-20.16 27.523h17.172c2.851 0 5.945.203 9.203.621 3.058.379 5.902 1.176 8.441 2.368 2.344 1.089 4.238 2.644 5.785 4.746 1.418 1.918 2.137 4.66 2.137 8.132 0 5.516-1.684 9.168-5.149 11.18-4.093 2.363-10.652 3.57-19.484 3.57h-18.105zm0 58.504h16.992c6.922 0 12.144 1.305 15.543 3.875 3.097 2.348 4.609 5.664 4.609 10.137 0 6.027-2.527 8.211-4.422 9.316-3.593 2.125-9.566 3.192-17.777 3.192h-14.945v-26.52"></path>
                      <path d="M881.57 221.715h-26.832a3.875 3.875 0 00-3.593 2.418l-11.313 27.773h-51.961l-10.945-27.734a3.888 3.888 0 00-3.61-2.457h-26.281a3.88 3.88 0 00-3.246 1.75 3.893 3.893 0 00-.309 3.687l57.395 131.926a3.882 3.882 0 003.559 2.328h20.304c1.543 0 2.95-.918 3.563-2.34l56.836-131.929a3.9 3.9 0 00-.321-3.672 3.894 3.894 0 00-3.246-1.75zm-82.668 58.078h29.723l-14.723 38.906z"></path>
                      <path d="M1024.9 221.715h-29.81a3.893 3.893 0 00-3.305 1.851l-55.797 90.832v-88.8a3.88 3.88 0 00-3.883-3.883h-23.48a3.882 3.882 0 00-3.883 3.883v131.925a3.88 3.88 0 003.883 3.883h31.117a3.86 3.86 0 003.297-1.832l54.5-87.793v85.742a3.88 3.88 0 003.881 3.883h23.48c2.15 0 3.89-1.734 3.89-3.883V225.598a3.885 3.885 0 00-3.89-3.883"></path>
                      <path d="M1081.52 220.59c-2.5 0-4.9.469-7.17 1.41-2.22.926-4.2 2.168-5.88 3.699-1.72 1.563-3.12 3.485-4.15 5.699-1.05 2.243-1.58 4.68-1.58 7.231 0 5.031 1.86 9.41 5.53 13.008 7.23 7.047 18.73 7.199 26.3.281 3.8-3.5 5.73-7.836 5.73-12.922 0-5.035-1.87-9.418-5.55-13.016-3.65-3.57-8.11-5.39-13.23-5.39"></path>
                      <path d="M1092.69 269.426h-22.36c-2.14 0-3.88 1.621-3.88 3.625v84.648c0 2.012 1.74 3.633 3.88 3.633h22.36c2.15 0 3.88-1.621 3.88-3.633v-84.648c0-2.004-1.73-3.625-3.88-3.625"></path>
                    </g>
                  </g>
                </svg>
              </Row>
              <Row className="mb-3">
                <h2 style={{ textAlign: "center" }}>Zeiterfassung</h2>
              </Row>
              <Row className="mb-3">
                <h4>Passwort ändern</h4>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      // disabled={lockedDown}
                      type="email"
                      placeholder="E-Mail-Adresse"
                      // value={commentary || ""}
                      onChange={(event) => {
                        emailHandler(event.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Form>
                  <Button
                    style={{ width: "100%" }}
                    // width="max"
                    // variant="primary"
                    // type="button"
                    size="lg"
                    onClick={sendChangePasswordEmailButtonHandler}
                  >
                    Passwort ändern
                  </Button>
                </Form>
              </Row>
              <Row className="mb-3">
                <Form>
                  <Button
                    style={{ width: "100%" }}
                    // width="max"
                    // variant="primary"
                    // type="button"
                    size="lg"
                    onClick={backToLoginButtonHandler}
                  >
                    Zurück zur Anmeldung
                  </Button>
                </Form>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={2} lg={3}></Col>
      </Row>
      <Row className="mb-3">
          <ToastContainer position={"bottom-center"}>
            <Toast
              onClose={() => setShowToast(false)}
              show={showToast}
              delay={5000}
              autohide
              bg={toastColor}
              // style={{ position: "absolute", zIndex: 1 }}
            >
              <Toast.Body className={toastColor === "primary" && "text-white"}>
                {toastMessage}
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </Row>
    </Container>
  );
}
