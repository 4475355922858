import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {
  let loggedIn = false;

  loggedIn = localStorage.getItem("loggedIn");
    return(
      loggedIn ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoutes;