import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import ThemeProvider from "react-bootstrap/ThemeProvider";

import Login from "./pages/Login";
import TimeTracker from "./pages/TimeTracker";
import ChangePassword from "./pages/ChangePassword"
import AssignNewPassword from "./pages/AssignNewPassword"
import PrivateRoutes from "./utility/PrivateRoutes";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider
      breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      minBreakpoint="xxs"
    >
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route element={<TimeTracker />} path="/timetracker" />
            </Route>
            <Route element={<Login />} path="/login" />
            <Route element={<Login />} path="/" />
            <Route element={<ChangePassword />} path="/changepassword" />
            <Route element={<AssignNewPassword />} path="/assignnewpassword/:passwordResetCode" />
          </Routes>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;

// npm install axios
// npm install date-fns --save
// npm install react-bootstrap bootstrap
// npm i react-query
